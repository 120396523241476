@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: 'Noto Sans KR', 'Arial', sans-serif;
  color: white
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130vh;
}

.btn {
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

body {
  text-align: center;
  background-color: #f0f2f5;
  overflow: hidden;
}