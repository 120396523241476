body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url('./assets/background.png');
  z-index: -1;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background: linear-gradient(to bottom, #212121, #171717);
  opacity: 0.7;
  z-index: -1;
}

.content-container {
  position: relative;
  z-index: 2;
  max-width: 100vw;
}

.hisa-title {
  font-family: 'Noto Sans KR', 'Arial', sans-serif;
  font-size: 5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  text-shadow: 2px 2px 25px white
}

/* Tablet */
@media (max-width: 768px) {
  .hisa-title {
    font-size: 4.5rem;
  }
}

/* Mobile */
@media (max-width: 576px) {
  .hisa-title {
    font-size: 4rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logo {
  width: 250px;
  background-color: #000000;
  border-radius: 50%;
  animation: rotate 10s linear infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounce {
  display: inline-block;
  animation: bounce 0.6s ease infinite;
}

.intro-text {
  font-size: 1.25rem;
  color: white;
  text-shadow: 2px 2px 10px black
}

/* Tablet */
@media (max-width: 768px) {
  .intro-text {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

/* Mobile */
@media (max-width: 576px) {
  .intro-text {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}

.lang-button {
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
  padding: 0px 8px;
  font-size: inherit;
  transition: color 0.3s ease;
  color: white;
}

.lang-button:hover {
  color: #007bff;
  outline: none;
}

.bottom-text a {
  font-size: 1rem;
  max-width: 600px;
}

.bottom-text a {
  color: #b3b3b3;
  ;
}

.btn-group-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.btn {
  font-size: 1.2rem;
  padding: 10px 20px;
  width: 200px;
}

@media (max-width: 768px) {
  .btn {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .btn {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}